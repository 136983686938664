
import * as directusRuntime$B9Fy79QmyF from '/codebuild/output/src4270767600/src/node_modules/@nuxt/image/dist/runtime/providers/directus'
import * as noneRuntime$MHgJIBuahs from '/codebuild/output/src4270767600/src/node_modules/@nuxt/image/dist/runtime/providers/none'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {
    "avatar": {
      "provider": "directus",
      "modifiers": {
        "fit": "cover",
        "width": 200,
        "height": 200,
        "quality": 75,
        "withoutEnlargement": true
      }
    },
    "card": {
      "provider": "directus",
      "modifiers": {
        "fit": "cover",
        "width": 900,
        "height": 1000,
        "quality": 75,
        "withoutEnlargement": true
      }
    },
    "gallery": {
      "provider": "directus",
      "modifiers": {
        "fit": "inside",
        "quality": 75,
        "width": 500,
        "withoutEnlargement": true
      }
    },
    "banner": {
      "provider": "directus",
      "modifiers": {
        "fit": "cover",
        "quality": 100,
        "width": 1200,
        "withoutEnlargement": true
      }
    }
  },
  "provider": "none",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['directus']: { provider: directusRuntime$B9Fy79QmyF, defaults: {"baseURL":"https://assets.wildcaretas.org.au/api/"} },
  ['none']: { provider: noneRuntime$MHgJIBuahs, defaults: {} }
}
        