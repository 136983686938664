import { default as indexNBCJTjLEIWMeta } from "/codebuild/output/src4270767600/src/pages/[slug]/index.vue?macro=true";
import { default as accept_45invitesibVQCwDP2Meta } from "/codebuild/output/src4270767600/src/pages/accept-invite.vue?macro=true";
import { default as extra_45purchase0n8AkG57dqMeta } from "/codebuild/output/src4270767600/src/pages/account/extra-purchase.vue?macro=true";
import { default as indexWcqU3AycBaMeta } from "/codebuild/output/src4270767600/src/pages/account/index.vue?macro=true";
import { default as settingsKGqPKPFYuZMeta } from "/codebuild/output/src4270767600/src/pages/account/settings.vue?macro=true";
import { default as subscriptionpIVfQ4LEByMeta } from "/codebuild/output/src4270767600/src/pages/account/subscription.vue?macro=true";
import { default as transactionsgwwruzZq7eMeta } from "/codebuild/output/src4270767600/src/pages/account/transactions.vue?macro=true";
import { default as update_45subscriptionIMmHnJ2GnoMeta } from "/codebuild/output/src4270767600/src/pages/account/update-subscription.vue?macro=true";
import { default as previewPjL72F5Q3QMeta } from "/codebuild/output/src4270767600/src/pages/admin/groups/[slug]/emails/[id]/preview.vue?macro=true";
import { default as compose4lWohHcN8oMeta } from "/codebuild/output/src4270767600/src/pages/admin/groups/[slug]/emails/compose.vue?macro=true";
import { default as indexz2SrJo5rOPMeta } from "/codebuild/output/src4270767600/src/pages/admin/groups/[slug]/emails/index.vue?macro=true";
import { default as emails8DQPHkb4JnMeta } from "/codebuild/output/src4270767600/src/pages/admin/groups/[slug]/emails.vue?macro=true";
import { default as editrrEPbREAfVMeta } from "/codebuild/output/src4270767600/src/pages/admin/groups/[slug]/events/[id]/edit.vue?macro=true";
import { default as registrationsSwOGcWyBcZMeta } from "/codebuild/output/src4270767600/src/pages/admin/groups/[slug]/events/[id]/registrations.vue?macro=true";
import { default as indexwSi35dVv5KMeta } from "/codebuild/output/src4270767600/src/pages/admin/groups/[slug]/events/index.vue?macro=true";
import { default as eventsK2LaJmaonkMeta } from "/codebuild/output/src4270767600/src/pages/admin/groups/[slug]/events.vue?macro=true";
import { default as filesAxX0Tfmz2aMeta } from "/codebuild/output/src4270767600/src/pages/admin/groups/[slug]/files.vue?macro=true";
import { default as galleryJXvGkuAuWXMeta } from "/codebuild/output/src4270767600/src/pages/admin/groups/[slug]/gallery.vue?macro=true";
import { default as group_45detailsQFQAzntCVAMeta } from "/codebuild/output/src4270767600/src/pages/admin/groups/[slug]/group-details.vue?macro=true";
import { default as group_45settingstoBh5RQTw0Meta } from "/codebuild/output/src4270767600/src/pages/admin/groups/[slug]/group-settings.vue?macro=true";
import { default as indexWsXsBf39jXMeta } from "/codebuild/output/src4270767600/src/pages/admin/groups/[slug]/index.vue?macro=true";
import { default as members2eXbEGRAoUMeta } from "/codebuild/output/src4270767600/src/pages/admin/groups/[slug]/members.vue?macro=true";
import { default as edittKCTT76l14Meta } from "/codebuild/output/src4270767600/src/pages/admin/groups/[slug]/news/[id]/edit.vue?macro=true";
import { default as index8VuqFyxHPtMeta } from "/codebuild/output/src4270767600/src/pages/admin/groups/[slug]/news/index.vue?macro=true";
import { default as newsCYjMsQEGffMeta } from "/codebuild/output/src4270767600/src/pages/admin/groups/[slug]/news.vue?macro=true";
import { default as _91slug_93jrbKxpeydHMeta } from "/codebuild/output/src4270767600/src/pages/donate/[slug].vue?macro=true";
import { default as indexkCZ7BFh92gMeta } from "/codebuild/output/src4270767600/src/pages/donate/index.vue?macro=true";
import { default as email_45unsubscribeXkgI2tFzGZMeta } from "/codebuild/output/src4270767600/src/pages/email-unsubscribe.vue?macro=true";
import { default as _91slug_93RXe4bc9oyDMeta } from "/codebuild/output/src4270767600/src/pages/events/[slug].vue?macro=true";
import { default as indexLEuFQgn3o6Meta } from "/codebuild/output/src4270767600/src/pages/events/index.vue?macro=true";
import { default as forgot_45passwordrPOfnmzSQ1Meta } from "/codebuild/output/src4270767600/src/pages/forgot-password.vue?macro=true";
import { default as filesxfCkcMo2N7Meta } from "/codebuild/output/src4270767600/src/pages/groups/[slug]/files.vue?macro=true";
import { default as galleryLxSzAmGLCRMeta } from "/codebuild/output/src4270767600/src/pages/groups/[slug]/gallery.vue?macro=true";
import { default as indexIDSsi8ZPJIMeta } from "/codebuild/output/src4270767600/src/pages/groups/[slug]/index.vue?macro=true";
import { default as shoplcy4mbEOdKMeta } from "/codebuild/output/src4270767600/src/pages/groups/[slug]/shop.vue?macro=true";
import { default as _91slug_93ZKahKczKygMeta } from "/codebuild/output/src4270767600/src/pages/groups/[slug].vue?macro=true";
import { default as index786nxy2y5aMeta } from "/codebuild/output/src4270767600/src/pages/groups/index.vue?macro=true";
import { default as indexeO7Q2CW7pkMeta } from "/codebuild/output/src4270767600/src/pages/index.vue?macro=true";
import { default as jointUnOak6PVpMeta } from "/codebuild/output/src4270767600/src/pages/join.vue?macro=true";
import { default as loginz8QPugL3INMeta } from "/codebuild/output/src4270767600/src/pages/login.vue?macro=true";
import { default as confirmation8oAvoNeQONMeta } from "/codebuild/output/src4270767600/src/pages/membership/confirmation.vue?macro=true";
import { default as indexOTs5SyUqgjMeta } from "/codebuild/output/src4270767600/src/pages/membership/index.vue?macro=true";
import { default as _91slug_93g9x1iPCUj8Meta } from "/codebuild/output/src4270767600/src/pages/news/[slug].vue?macro=true";
import { default as indexBFvBkCcLuzMeta } from "/codebuild/output/src4270767600/src/pages/news/index.vue?macro=true";
import { default as reset_45passwordZMFX3ZQKJXMeta } from "/codebuild/output/src4270767600/src/pages/reset-password.vue?macro=true";
import { default as setup_452famcR3MCDXT6Meta } from "/codebuild/output/src4270767600/src/pages/setup-2fa.vue?macro=true";
import { default as shopojCTANwsvhMeta } from "/codebuild/output/src4270767600/src/pages/shop.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug()",
    component: () => import("/codebuild/output/src4270767600/src/pages/[slug]/index.vue")
  },
  {
    name: "accept-invite",
    path: "/accept-invite",
    component: () => import("/codebuild/output/src4270767600/src/pages/accept-invite.vue")
  },
  {
    name: "account-extra-purchase",
    path: "/account/extra-purchase",
    meta: extra_45purchase0n8AkG57dqMeta || {},
    component: () => import("/codebuild/output/src4270767600/src/pages/account/extra-purchase.vue")
  },
  {
    name: "account",
    path: "/account",
    meta: indexWcqU3AycBaMeta || {},
    component: () => import("/codebuild/output/src4270767600/src/pages/account/index.vue")
  },
  {
    name: "account-settings",
    path: "/account/settings",
    meta: settingsKGqPKPFYuZMeta || {},
    component: () => import("/codebuild/output/src4270767600/src/pages/account/settings.vue")
  },
  {
    name: "account-subscription",
    path: "/account/subscription",
    meta: subscriptionpIVfQ4LEByMeta || {},
    component: () => import("/codebuild/output/src4270767600/src/pages/account/subscription.vue")
  },
  {
    name: "account-transactions",
    path: "/account/transactions",
    meta: transactionsgwwruzZq7eMeta || {},
    component: () => import("/codebuild/output/src4270767600/src/pages/account/transactions.vue")
  },
  {
    name: "account-update-subscription",
    path: "/account/update-subscription",
    meta: update_45subscriptionIMmHnJ2GnoMeta || {},
    component: () => import("/codebuild/output/src4270767600/src/pages/account/update-subscription.vue")
  },
  {
    name: emails8DQPHkb4JnMeta?.name,
    path: "/admin/groups/:slug()/emails",
    meta: emails8DQPHkb4JnMeta || {},
    component: () => import("/codebuild/output/src4270767600/src/pages/admin/groups/[slug]/emails.vue"),
    children: [
  {
    name: "admin-groups-slug-emails-id-preview",
    path: ":id()/preview",
    meta: previewPjL72F5Q3QMeta || {},
    component: () => import("/codebuild/output/src4270767600/src/pages/admin/groups/[slug]/emails/[id]/preview.vue")
  },
  {
    name: "admin-groups-slug-emails-compose",
    path: "compose",
    meta: compose4lWohHcN8oMeta || {},
    component: () => import("/codebuild/output/src4270767600/src/pages/admin/groups/[slug]/emails/compose.vue")
  },
  {
    name: "admin-groups-slug-emails",
    path: "",
    meta: indexz2SrJo5rOPMeta || {},
    component: () => import("/codebuild/output/src4270767600/src/pages/admin/groups/[slug]/emails/index.vue")
  }
]
  },
  {
    name: eventsK2LaJmaonkMeta?.name,
    path: "/admin/groups/:slug()/events",
    meta: eventsK2LaJmaonkMeta || {},
    component: () => import("/codebuild/output/src4270767600/src/pages/admin/groups/[slug]/events.vue"),
    children: [
  {
    name: "admin-groups-slug-events-id-edit",
    path: ":id()/edit",
    meta: editrrEPbREAfVMeta || {},
    component: () => import("/codebuild/output/src4270767600/src/pages/admin/groups/[slug]/events/[id]/edit.vue")
  },
  {
    name: "admin-groups-slug-events-id-registrations",
    path: ":id()/registrations",
    meta: registrationsSwOGcWyBcZMeta || {},
    component: () => import("/codebuild/output/src4270767600/src/pages/admin/groups/[slug]/events/[id]/registrations.vue")
  },
  {
    name: "admin-groups-slug-events",
    path: "",
    meta: indexwSi35dVv5KMeta || {},
    component: () => import("/codebuild/output/src4270767600/src/pages/admin/groups/[slug]/events/index.vue")
  }
]
  },
  {
    name: "admin-groups-slug-files",
    path: "/admin/groups/:slug()/files",
    meta: filesAxX0Tfmz2aMeta || {},
    component: () => import("/codebuild/output/src4270767600/src/pages/admin/groups/[slug]/files.vue")
  },
  {
    name: "admin-groups-slug-gallery",
    path: "/admin/groups/:slug()/gallery",
    meta: galleryJXvGkuAuWXMeta || {},
    component: () => import("/codebuild/output/src4270767600/src/pages/admin/groups/[slug]/gallery.vue")
  },
  {
    name: "admin-groups-slug-group-details",
    path: "/admin/groups/:slug()/group-details",
    meta: group_45detailsQFQAzntCVAMeta || {},
    component: () => import("/codebuild/output/src4270767600/src/pages/admin/groups/[slug]/group-details.vue")
  },
  {
    name: "admin-groups-slug-group-settings",
    path: "/admin/groups/:slug()/group-settings",
    meta: group_45settingstoBh5RQTw0Meta || {},
    component: () => import("/codebuild/output/src4270767600/src/pages/admin/groups/[slug]/group-settings.vue")
  },
  {
    name: "admin-groups-slug",
    path: "/admin/groups/:slug()",
    meta: indexWsXsBf39jXMeta || {},
    component: () => import("/codebuild/output/src4270767600/src/pages/admin/groups/[slug]/index.vue")
  },
  {
    name: "admin-groups-slug-members",
    path: "/admin/groups/:slug()/members",
    meta: members2eXbEGRAoUMeta || {},
    component: () => import("/codebuild/output/src4270767600/src/pages/admin/groups/[slug]/members.vue")
  },
  {
    name: newsCYjMsQEGffMeta?.name,
    path: "/admin/groups/:slug()/news",
    meta: newsCYjMsQEGffMeta || {},
    component: () => import("/codebuild/output/src4270767600/src/pages/admin/groups/[slug]/news.vue"),
    children: [
  {
    name: "admin-groups-slug-news-id-edit",
    path: ":id()/edit",
    meta: edittKCTT76l14Meta || {},
    component: () => import("/codebuild/output/src4270767600/src/pages/admin/groups/[slug]/news/[id]/edit.vue")
  },
  {
    name: "admin-groups-slug-news",
    path: "",
    meta: index8VuqFyxHPtMeta || {},
    component: () => import("/codebuild/output/src4270767600/src/pages/admin/groups/[slug]/news/index.vue")
  }
]
  },
  {
    name: "donate-slug",
    path: "/donate/:slug()",
    component: () => import("/codebuild/output/src4270767600/src/pages/donate/[slug].vue")
  },
  {
    name: "donate",
    path: "/donate",
    component: () => import("/codebuild/output/src4270767600/src/pages/donate/index.vue")
  },
  {
    name: "email-unsubscribe",
    path: "/email-unsubscribe",
    component: () => import("/codebuild/output/src4270767600/src/pages/email-unsubscribe.vue")
  },
  {
    name: "events-slug",
    path: "/events/:slug()",
    component: () => import("/codebuild/output/src4270767600/src/pages/events/[slug].vue")
  },
  {
    name: "events",
    path: "/events",
    component: () => import("/codebuild/output/src4270767600/src/pages/events/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: () => import("/codebuild/output/src4270767600/src/pages/forgot-password.vue")
  },
  {
    name: _91slug_93ZKahKczKygMeta?.name,
    path: "/groups/:slug()",
    component: () => import("/codebuild/output/src4270767600/src/pages/groups/[slug].vue"),
    children: [
  {
    name: "groups-slug-files",
    path: "files",
    component: () => import("/codebuild/output/src4270767600/src/pages/groups/[slug]/files.vue")
  },
  {
    name: "groups-slug-gallery",
    path: "gallery",
    component: () => import("/codebuild/output/src4270767600/src/pages/groups/[slug]/gallery.vue")
  },
  {
    name: "groups-slug",
    path: "",
    component: () => import("/codebuild/output/src4270767600/src/pages/groups/[slug]/index.vue")
  },
  {
    name: "groups-slug-shop",
    path: "shop",
    component: () => import("/codebuild/output/src4270767600/src/pages/groups/[slug]/shop.vue")
  }
]
  },
  {
    name: "groups",
    path: "/groups",
    component: () => import("/codebuild/output/src4270767600/src/pages/groups/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src4270767600/src/pages/index.vue")
  },
  {
    name: "join",
    path: "/join",
    component: () => import("/codebuild/output/src4270767600/src/pages/join.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/codebuild/output/src4270767600/src/pages/login.vue")
  },
  {
    name: "membership-confirmation",
    path: "/membership/confirmation",
    component: () => import("/codebuild/output/src4270767600/src/pages/membership/confirmation.vue")
  },
  {
    name: "membership",
    path: "/membership",
    meta: indexOTs5SyUqgjMeta || {},
    component: () => import("/codebuild/output/src4270767600/src/pages/membership/index.vue")
  },
  {
    name: "news-slug",
    path: "/news/:slug()",
    component: () => import("/codebuild/output/src4270767600/src/pages/news/[slug].vue")
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/codebuild/output/src4270767600/src/pages/news/index.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    component: () => import("/codebuild/output/src4270767600/src/pages/reset-password.vue")
  },
  {
    name: "setup-2fa",
    path: "/setup-2fa",
    meta: setup_452famcR3MCDXT6Meta || {},
    component: () => import("/codebuild/output/src4270767600/src/pages/setup-2fa.vue")
  },
  {
    name: "shop",
    path: "/shop",
    component: () => import("/codebuild/output/src4270767600/src/pages/shop.vue")
  }
]